
let currentEnv = (()=>{
    let origin = window.location.host.toLowerCase();
    let env;
    if(origin.indexOf('localhost') > -1) {
        env =  "staging"
    }
    else if(origin.indexOf('cms.staging.lancomepassport') > -1) {
        env =  "staging"
    }
    else if(origin.indexOf('cms.lancomebrandmeeting') > -1) {
        env =  "brandmeeting-production"
    }
    else if(origin.indexOf('cms.staging.lancomebrandmeeting') > -1) {
        env =  "brandmeeting-staging"
    }
    else{
        env = "production"
    }
    //env = "brandmeeting-staging";
    console.log("ENVIRONMENT:::::"+env);
    // return "production";
    return env;
})();
let CONSTANTS;
if(currentEnv == "staging" ){

    CONSTANTS = {
        CURRENT_ENV: currentEnv,
        API_USER_TOKEN: '85bdf869a799bc01',
        API_MONGO_BASE_URL: 'https://live.staging.lancomepassporttoparis.com/',
        API_MONGO_BASE_CHAT_URL: 'https://live.staging.lancomepassporttoparis.com/',
        SOCKET_SERVER_URL: 'https://live.staging.lancomepassporttoparis.com',
        SANDIA_API_BASE: 'https://api2.lancomepassporttoparis.com/',
        SANDIA_CAMPAIGN_ID: 104,
        SANDIA_EVENT_ID: 1722
        
        // SOCKET_SERVER_URL: 'http://localhost:3000',
        

    }
}
else if(currentEnv === 'production'){
    CONSTANTS = {
        CURRENT_ENV: currentEnv,
        API_USER_TOKEN: '953401f5698f39ba',
        API_MONGO_BASE_URL: 'https://lancomepassporttoparis.com/',
        SOCKET_SERVER_URL: 'https://lancomepassporttoparis.com',
        API_MONGO_BASE_CHAT_URL: 'https://live.lancomepassporttoparis.com/',
        SANDIA_API_BASE: 'https://api2.lancomepassporttoparis.com/',
        SANDIA_CAMPAIGN_ID: 104,
        SANDIA_EVENT_ID: 1722
    };
}
else if(currentEnv === 'brandmeeting-production' || currentEnv == "production"){
    CONSTANTS = {
        CURRENT_ENV: currentEnv,
        API_USER_TOKEN: '953401f5698f39ba',
        API_MONGO_BASE_URL: 'https://lancomebrandmeeting.com/',
        SOCKET_SERVER_URL: 'https://lancomebrandmeeting.com',
        API_MONGO_BASE_CHAT_URL: 'https://lancomebrandmeeting.com/',
        // SANDIA_API_BASE: 'https://api2.lancomepassporttoparis.com/',
        SANDIA_API_BASE: 'https://sandia.lancomebrandmeeting.com/',
        SANDIA_CAMPAIGN_ID: 104,
        SANDIA_EVENT_ID: 1620
    };
}
else if(currentEnv === 'brandmeeting-staging' || currentEnv == "development"){
    CONSTANTS = {
        CURRENT_ENV: currentEnv,
        API_USER_TOKEN: '953401f5698f39ba',
        API_MONGO_BASE_URL: 'https://staging.lancomebrandmeeting.com/',
        SOCKET_SERVER_URL: 'https://staging.lancomebrandmeeting.com',
        API_MONGO_BASE_CHAT_URL: 'https://staging.lancomebrandmeeting.com/',
        // SANDIA_API_BASE: 'https://api2.lancomepassporttoparis.com/',
        SANDIA_API_BASE: 'https://sandia.lancomebrandmeeting.com/',
        SANDIA_CAMPAIGN_ID: 104,
        SANDIA_EVENT_ID: 1620
    };
}


export default CONSTANTS;

